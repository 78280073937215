
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React from 'react'
import App from 'next/app'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

import '../assets/scss/global.scss'
import { setDeviceInfo, setFooterMenu, setHeaderMenu, setHeaderSidebarMenu } from '@shared/libs/menu'
import { setCurrentProps } from '@shared/libs/current-props'

const AppLayout = dynamic(() => import('@shared/components/layout/app'))

function MyApp({ Component, pageProps, headerMenu, footerMenu, headerSidebarMenu, isAmp, device }) {
  const props = { ...pageProps, isAmp, device }
  setCurrentProps(props)
  setDeviceInfo(device)
  setHeaderMenu(headerMenu)
  setFooterMenu(footerMenu)
  setHeaderSidebarMenu(headerSidebarMenu)

  return (
    <AppLayout>
      <Component {...props} />
    </AppLayout>
  )
}
MyApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any,
  headerMenu: PropTypes.array,
  footerMenu: PropTypes.array,
  headerSidebarMenu: PropTypes.array,
  token: PropTypes.string,
  isMobileWebView: PropTypes.bool,
  isAmp: PropTypes.bool,
  device: PropTypes.object
}

MyApp.getInitialProps = async (appContext) => {
  const userAgent = appContext?.ctx?.req?.headers['user-agent']

  const [appProps, graphql, utils, query] = await Promise.all([
    App.getInitialProps(appContext),
    import('@shared-components/queryGraphql'),
    import('@shared/utils'),
    import('@graphql/common/common.query')
  ])

  let data = {}
  try {
    const d = await graphql.default(query.HEADER_FOOTER_SIDEBAR_MENU)
    data = d?.data || {}
  } catch (error) {
    // console.log(error)
  }

  const ctTheme = appContext?.ctx?.req?.cookies?.ctTheme
  const isAmp = !!appContext?.router?.query?.amp || appContext?.router?.asPath?.startsWith('/amp/')
  const device = utils?.getDeviceDetail(userAgent)
  const isMobileWebView = !!appContext?.router?.query?.isMobileWebView
  const mobileWebViewTheme = appContext?.router?.query?.theme

  return {
    ...appProps,
    headerMenu: data?.getMenuTree?.aResults || [],
    footerMenu: data?.getFrontFooter || [],
    headerSidebarMenu: data?.getFrontSlider || [],
    ctTheme,
    isAmp,
    isMobileWebView,
    mobileWebViewTheme,
    device
    // userAgent: userAgent || JSON.stringify(userAgent) || ''
  }
}

const __Page_Next_Translate__ = MyApp

// export function reportWebVitals(metric) {
//   console.log(metric)
// }


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  